import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"

import Hero from "../components/hero/heroSimple/heroSimple"
import ShowcaseItems from "../components/showcase/showcaseItems"
import ShowcaseButtons from "../components/showcase/showcaseButtons"
import Seo from "../components/seo/seo"
import Cta from "../components/cta/cta"
import CtaForm from "../components/ctaForm/ctaForm"

const Showcase = ({ data }) => {
  const { content, seo } = data.sanityShowcase.tabs
  const categories = data.allSanityShowcaseCategory.nodes

  return (
    <Layout>
      <Seo isBlogPost={false} {...seo} />
      <Hero blocks={content.hero[0].children} subtitle={content.subtitle} />
      <ShowcaseButtons categories={categories} />
      <ShowcaseItems items={content.showcase} />
      {content.cta?.__typename === "SanityCtaForm" ? <CtaForm {...content.cta} background="blue" /> : null}
      {content.cta?.__typename === "SanityCta" ? <Cta {...content.cta} background="blue" /> : null}
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityShowcase {
      tabs {
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
        content {
          hero: _rawHero
          subtitle
          showcase {
            _key
            title
            slug {
              current
            }
            text
            featured
            description: _rawDescription(resolveReferences: { maxDepth: 10 })
            category {
              title
            }
            image {
              _rawAsset(resolveReferences: { maxDepth: 10 })
              alt
            }
            action {
              ... on SanityVideoEmbed {
                _key
                _type
                instilled {
                  url
                  title
                  aspect
                  custom {
                    width
                    height
                  }
                }
                url
              }
            }
          }
          cta {
            ... on SanityCta {
              ...ctaFragment
            }
            ... on SanityCtaForm {
              ...ctaFormFragment
            }
          }
        }
      }
    }
    allSanityShowcaseCategory {
      nodes {
        slug {
          current
        }
        title
      }
    }
  }
`

export default Showcase
